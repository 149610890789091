const abcTheme = {
  foreground: '#ffffff',
  background: '#000000',
  cursor: '#ffffff',
  selection: 'rgba(255, 255, 255, 0.3)',
  black: '#000000',
  brightBlack: '#808080',
  red: '#e06c75',
  brightRed: '#e06c75',
  green: '#A4EFA1',
  brightGreen: '#A4EFA1',
  yellow: '#EDDC96',
  brightYellow: '#EDDC96',
  magenta: '#e39ef7',
  brightMagenta: '#e39ef7',
  blue: '#5fcbd8',
  brightBlue: '#5fcbd8',
  cyan: '#5fcbd8',
  brightCyan: '#5fcbd8',
  white: '#d0d0d0',
  brightWhite: '#ffffff',
}

const defaultTheme = {
  foreground: '#ffffff',
  background: '#1b212f',
  cursor: '#ffffff',
  selection: 'rgba(255, 255, 255, 0.3)',
  black: '#000000',
  brightBlack: '#808080',
  red: '#ce2f2b',
  brightRed: '#f44a47',
  green: '#00b976',
  brightGreen: '#05d289',
  yellow: '#e0d500',
  brightYellow: '#f4f628',
  magenta: '#bd37bc',
  brightMagenta: '#d86cd8',
  blue: '#1d6fca',
  brightBlue: '#358bed',
  cyan: '#00a8cf',
  brightCyan: '#19b8dd',
  white: '#e5e5e5',
  brightWhite: '#ffffff',
}

const theme = {
  default_theme: defaultTheme,
  abc_theme: abcTheme,
}

export default theme
